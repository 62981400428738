/**
 * Copyright 2020 AutoZone, Inc.
 * Content is confidential to and proprietary information of AutoZone, Inc., its
 * subsidiaries and affiliates.
 */
import { LandingPageComp } from '@/features/landingPage/pages/LandingPage';
import { prefetchContentStackLandingPageData } from '@/features/landingPage/api/getContentStackLandingPageData';
import { type PageContextType } from '@/next-types';
import { configureSharedPageData } from '@/utils/configureSharedPageData.server';
import { dehydrate } from '@tanstack/react-query';
import { getHeaderDataFromCache } from '@/features/header/api/getHeader';
import { prefetchRewardDetails, useRewardDetails } from '@/features/rewards/api/getRewardDetails';

export const config = {
  unstable_JsPreload: false,
};

function RewardsPage() {
  const { data } = useRewardDetails();
  if (data?.creditsPerReward === '3') {
    return <LandingPageComp pageName="faster" />;
  }
  return <LandingPageComp pageName="rewards" />;
}

RewardsPage.getInitialProps = async (ctx: PageContextType) => {
  if (__IS_SERVER__) {
    const { axios, queryClient, initialAppState } = await configureSharedPageData(ctx);
    const headerData = getHeaderDataFromCache(queryClient);

    const userAnonymous = headerData?.myAccountMap?.anonymous !== 'false';

    const prefetchLandingPageCall = prefetchContentStackLandingPageData(
      queryClient,
      {
        ...ctx,
        query: {
          ...ctx.query,
          pageName: 'rewards',
        },
      },
      headerData?.segments ?? []
    );

    let rewardsActivityPromise: Promise<void> | undefined;

    if (!userAnonymous) {
      rewardsActivityPromise = prefetchRewardDetails(axios, queryClient, !userAnonymous);
    }

    await Promise.all([prefetchLandingPageCall, rewardsActivityPromise]);

    return {
      dehydratedState: dehydrate(queryClient),
      initialAppState,
    };
  }

  return {};
};

export default RewardsPage;
